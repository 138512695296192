#reset{
    width: 100vw;
    height: 100vh;
    background: url(/modules/users/img/identification_fond.jpg) no-repeat center fixed; 
    -webkit-background-size: cover; /* pour anciens Chrome et Safari */
    background-size: cover; /* version standardisée */
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    form{
        display: block;
        width: 265px;
        margin: auto;
        font-family: Lato, sans-serif;
        color: white;
        legend{
            padding: 0;
            font-size: 1.5em;
            font-weight: 300;
            text-align: center;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        .case1, .case2{
            margin-bottom: 10px;
            input{
                width: 255px;
                height: 25px;
                border: none;
                padding: 5px;
            }
        }
        .case3{
            button{
                width: 265px;
                height: 40px;
                background-image: linear-gradient(to bottom,#3f3f3f,#363636 5px,#303030);
                box-shadow: 0 0 4px 1px rgba(0,0,0,.75);
                border: none;
                color: #fff;
            }
            button:hover{
                background: #363636;
                box-shadow: 0 0 2px 1px rgba(0,0,0,.75);
            }
        }
    }
}
